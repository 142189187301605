import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import BreadCrumb from '../../../components/Breadcrumbs';
import { capitalizingPhrase } from '../../../utils/format';
import MenteesTable from './menteesTable';
import MentorsTable from './mentorsTable';
import SessionsTable from './SessionsTable';
import TabContainer from './tabContainer';
import SurveyTable from './surveyTable';
import ReportModal from './ReportModal';

import { Paper } from '@material-ui/core';

import CoursesAPI from '../../CoursesContainer/course-api';
import { utils, writeFileXLSX } from 'xlsx';
import Alert from '../../../components/common/Alert';

const ProjectInfoContainer = (props) => {
  const history = useHistory();
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  //routerProps
  const {
    location: { state: routeState },
  } = props;

  const tabs = [
    { title: 'Mentees', Component: MenteesTable, buttonText: 'Nuevo usuario' },
    { title: 'Mentores', Component: MentorsTable, buttonText: 'Nuevo usuario' },
    {
      title: 'Reuniones',
      Component: SessionsTable,
      buttonText: 'Nueva Reunión',
    },
    {
      title: 'Encuestas',
      Component: SurveyTable,
      buttonText: '',
    },
  ];

  const links = [
    { url: '/clientes', text: 'Clientes' },
    {
      url: '/clientes/detalles',
      text: 'Detalle del cliente',
      state: { client: routeState.clientId },
    },
    { url: '/proyecto', text: 'Proyecto' },
  ];
  const downloadReport = async (announcementId) => {
    try {
      const {
        data: { objects },
      } = await CoursesAPI.getAnnouncementReport(announcementId);

      const wb = utils.book_new();
      objects.forEach((data) => {
        const ws = utils.json_to_sheet(data.info);
        utils.book_append_sheet(wb, ws, data.name);
      });

      writeFileXLSX(wb, `${objects[0]?.name}-.xlsx`, {
        compression: true,
        cellDates: true,
      });
      setMessage('¡El reporte de cursos se ha descargado exitosamente!');
    } catch (error) {
      setMessage(
        'Ocurrió un error al descargar el informe. Por favor, inténtalo de nuevo.'
      );
    }
  };


  return (
    <>
      <ReportModal open={openModal} announcementId={routeState.announcementId} onClose={()=> setOpenModal(false)} />
      <div className="content-panel user-container">
        <BreadCrumb links={links} />
        {/* TITLE AND INFO ABOUT PROJECT */}
        {message && (
          <div className="success-message">
            <Alert type="success">{message}</Alert>
          </div>
        )}

        {error && (
          <div className="error-message">
            <Alert type="error">{error}</Alert>
          </div>
        )}
        <div className="project__info-container">
          <Paper>
            <div className="client-details__info-container">
              <div className="grid">
                <h2>{capitalizingPhrase(routeState.client)}</h2>
              </div>

              <div className="flex-row">
                <span>{capitalizingPhrase(routeState.title)}</span>
                <div className="flex-row">
                  <div
                    className="client-details__flex-columns link"
                    onClick={() => {
                      history.push('/clientes/detalles/proyecto/editar', {
                        announcementId: routeState.announcementId,
                        title: routeState.title,
                        client: routeState.client,
                        clientId: routeState.clientId,
                      });
                    }}
                  >
                    <span className="material-symbols-outlined">edit</span>
                    <span>Editar</span>
                  </div>
                  <div
                    className="client-details__flex-columns link"
                    onClick={() => downloadReport(routeState.announcementId)}
                  >
                    <span className="material-symbols-outlined">
                      cloud_download
                    </span>
                    <span>Descargar reporte cursos</span>
                  </div>
                  <div
                    className="client-details__flex-columns link"
                    onClick={() => setOpenModal(true)}
                  >
                    <span className="material-symbols-outlined">
                      cloud_download
                    </span>
                    <span>Generar reporte de cursos desde LMS</span>
                  </div>
                </div>
              </div>
            </div>
          </Paper>
        </div>
        <TabContainer
          tabs={tabs}
          variant="bordered"
          buttonText="Nuevo usuario"
          project={routeState}
        />
      </div>
    </>
  );
};
export default ProjectInfoContainer;
